import { render, staticRenderFns } from "./game.vue?vue&type=template&id=b177f8e8&"
import script from "./game.vue?vue&type=script&lang=js&"
export * from "./game.vue?vue&type=script&lang=js&"
import style0 from "./game.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "game.vue"
export default component.exports