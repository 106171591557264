<template>
    <div class="home">
        <div class="">
            <swiper :options="swiperOption">
                <swiper-slide v-for="(slide, index) in swiperSlides" :key="index" class="swiper-wrap">
                     <div style="width:100%;">
                         <img :src="slide" style="height:100%;width:100%;" />
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div class="game-center">
             <div class="game-center-title">
                    <h4>精品游戏</h4>
            </div>
            <div class="game-center-img clearfix">
                <ul>
                    <li  v-for="(item, i) in lists" :key="i" @click="goinnew(i)">
                        <img :src="item.img" alt="">
                        <div class="game-center-img-title">
                            <a href="">{{item.title}}</a>
                            <span>{{item.mark}}</span>
                        </div>
                    </li>
                </ul>
                <!-- <div class="game-center-infor">
                    <div>
                        <h5>热门信息</h5>
                    </div>
                    <ul>
                        <li class="game-center-infor-all-in clearfix"  v-bind:class="{ classrmoney:classenable&&i==current}" v-on:mouseover="addClassload(i)"  v-for="(item, i) in popular" :key="i">
                            <div class="game-change-one clearfix" style="display:block;" v-bind:class="{ classjinru:classenable&&i==current}">
                                <a href="">{{item.title}}</a>
                                <i>{{item.time}}</i>
                                <span>第一区</span>
                            </div>
                            <div  class="game-change-two clearfix" style="display:none;" v-bind:class="{ classjinchu:classenable&&i==current}">
                                <img :src="item.img">
                                <div class="name-time">
                                    <span>王者传奇</span>
                                    <span>{{item.time}}</span>
                                </div>
                                <div class="go-in" @click="goGame">进入</div>
                            </div>
                        </li>
                    </ul>
                </div> -->
            </div>
        </div>
        <footer-info></footer-info>
    </div>
</template>

<script>
import FooterInfo from "../components/footer";
import newqu1 from "../assets/images/new_change(2).jpg";
import newqu2 from "../assets/images/new_change(1).jpg";

import banner1 from "../assets/images/banner4 (1).jpg";
import banner2 from "../assets/images/banner4 (2).jpg";
import icon_1 from "../assets/images/icon_50-50-1.png";
import icon_2 from "../assets/images/icon_50-50-2.png";
import icon_3 from "../assets/images/icon_50-50-3.png";
import icon_4 from "../assets/images/icon_50-50-4.png";
import icon_5 from "../assets/images/icon_50-50-5.png";
export default {
  name: "carrousel",
  components: {
    FooterInfo
  },
  data() {
    return {
      formInline: {
        user: "",
        password: ""
      },
      ruleInline: {
        user: [
          {
            required: true,
            message: "Please fill in the user name",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "Please fill in the password.",
            trigger: "blur"
          },
          {
            type: "string",
            min: 6,
            message: "The password length cannot be less than 6 bits",
            trigger: "blur"
          }
        ]
      },
      swiperOption: {
        pagination: {
          el: ".swiper-pagination"
        }
      },
      swiperSlides: [banner1, banner2],
      popular: [
        {
          title: "红月传说",
          img: icon_1,
          time: "2018-09-20"
        },
        {
          title: "散人传说",
          img: icon_2,
          time: "2018-09-20"
        },
        {
          title: "鹿鼎记",
          img: icon_3,
          time: "2018-09-20"
        },
        {
          title: "血盟荣耀",
          img: icon_4,
          time: "2018-09-20"
        },
        {
          title: "金装传奇",
          img: icon_5,
          time: "2018-09-20"
        }
      ],
      classenable: true,
      current: 0,
      lists: [
        {
          title: "仙剑奇侠传",
          img: newqu1,
          mark: "快来"
        },
        {
          title: "三国志",
          img: newqu2,
          mark: "快来"
        }
      ]
    };
  },
  mounted() {},
  methods: {
     goinnew(data) {
      if (data == 0) {
        this.$router.push("/godownload");
      }
      if(data ==1){
        this.$router.push("/sgzdownload");
      }
    },
    addClassload(i) {
      this.classenable = true;
      this.current = i;
    },
    removeClassload(i) {
      this.classenable = false;
      this.current = i;
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.$Message.success("Success!");
        } else {
          this.$Message.error("Fail!");
        }
      });
    },
    goGame() {
      window.open("https://www.yingxiongbb.cn/detail/detail.html", "_blank");
    }
  }
};
</script>

<style>
.game-center {
  width: 100%;
  height: 500px;
}
.game-center-img {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  cursor: pointer;
}
.game-center-img > ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width:100%;
  height: 100%;
}
.game-center-img > ul li {
  width: 400px;
  height: 430px;
  float: left;
  margin-right: 45px;
}
.game-center-img > ul li:nth-child(4) {
  margin-top: 25px;
}
.game-center-img > ul li:nth-child(5) {
  margin-top: 25px;
}
.game-center-img > ul li:nth-child(6) {
  margin-top: 25px;
}
.game-center-img > ul li img {
  width: 400px;
}
.game-center-infor {
  box-sizing: border-box;
  padding: 20px;
  width: 350px;
  height: 345px;
  box-shadow: 0px 0px 10px 1px#e6e6e6;
}
.game-center-infor ul {
  margin-top: 30px;
}
.game-center-infor ul .game-center-infor-all-in {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 14px;
  border-bottom: 1px solid #e6e6e6;
}
.classrmoney {
  height: 70px !important;
  background: #e92e3c;
  color: #fff;
}
.classjinru {
  display: none !important;
}
.classjinchu {
  display: block !important;
}
.game-change-one {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.game-change-one i {
  margin-left: 20px;
}
.game-change-one span {
  float: right;
}
.game-change-two {
  width: 100%;
  height: 60px;
}
.game-change-two img {
  float: left;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.game-change-two .name-time {
  display: flex;
  flex-direction: column;
  float: left;
  box-sizing: border-box;
  padding: 10px;
}
.game-change-two .go-in {
  margin-top: 25px;
  width: 60px;
  height: 20px;
  background: #fff;
  color: #e92e3c;
  text-align: center;
  line-height: 20px;
  float: right;
  cursor: pointer;
}
.game-center-infor ul .game-center-infor-all-in a {
  color: #333;
}
.game-center-title {
  margin: 0 auto;
  width: 1200px;
  padding: 24px 0;
}
.game-center-title h4 {
  padding-left: 20px;
  color: red;
  border-left: 4px solid red;
}
.game-center-img-title {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 10px;
  border: 1px solid #e6e6e6;
  border-top: none;
  line-height: 22px;
}
.game-center-img-title a {
  color: #000;
}
.game-center-img-title span {
  float: right;
  padding: 0 10px;
}
.game-center-img-title i {
  width: 68px;
  height: 22px;
  float: right;
  color: #fff;
  background: #f6193c;
  text-align: center;
}
.game-center-img-title i:hover {
  background: #fff;
  text-align: center;
  color: #f6193c;
  border: 1px solid #f6193c;
}

/* .login-box h6 {
    margin-bottom: 16px;
}
.login-box a {
    color: blue !important;
}
.hots li{
    position: relative;
}
.login-footer {
    text-align: right;
    margin-top: 46px;
}
.ivu-form-item{
    width: 100%;
}
.login-btn {
    display: flex;
    justify-content: space-between;
}
.item-show, .item-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 8px;
}
.item-row {
    border-bottom: 1px solid #e1e1e1;
}
.hots li .item-show {
    display: none;
    background-color: red;
    color: #fff;
}
.hots li:hover .item-show {
    display: flex;
}
.hots li:hover .item-row {
    display: none;
}
.item-show img{
    width: 48px;
    height: 48px;
}
.banner {
    position: relative;
}
.login {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
}
.login-box {
    height: 300px;
    width: 250px;
    background-color: #fff;
    padding: 24px;
    float: right;
}
.swiper-wrap {
    text-align: center;
    background-color: #24242f;
} */
</style>
